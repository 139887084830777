import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'

export default function About() {
    return (
        <Layout>
            <PageTitle text="О проекте" />
            <div className="about-team">
                <img className="about-team-img" src='/img/our-team.png' alt="Комманда" />
            </div>
            <div className="about-description">
                <h5>Один из наших основных и успешных проектов под названием «Zarafshon» был основан в 2011 году.</h5>
                <div>
                    Цель «Zarafshon» является производство оборудований и систем для железнодорожной отрасли таких как:
                    <ul>
                        <li>Пассажирское вагоностроение</li>
                        <li>Системы и оборудования СЦБ для железнодорожных станций</li>
                        <li>Железнодорожный тяговый и маторвагонный подвижной состав</li>
                    </ul>
                    Наше оборудование предназначено для эксплуатации в Узбекистане и в других странах СНГ.
                </div>
            </div>
        </Layout>
    )
}
