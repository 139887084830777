import React from 'react'
import { Helmet } from 'react-helmet'

export default function PageTitle(props) {
    return (
        <>
            <Helmet>
                <title>{props.text} | zarafshon.org</title>
            </Helmet>
            <h1 className="pageTitle">{props.text}</h1>
        </>
    )
}
